import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertHourFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function ReservationModal(props) {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [course, setCourse] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [person, setPerson] = useState(2); // 2, 3, 4 중 하나
  const [minimumPerson, setMinimumPerson] = useState(2);
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [hole, setHole] = useState("");
  // const [cartYn, setCartYn] = useState(false);
  // const [caddyYn, setCaddyYn] = useState(false);
  const [notes, setNotes] = useState("");
  const btnBookingRef = useRef(null);
  const btnCloseRef = useRef(null);

  const reservation = async () => {
    if (!name) {
      Swal.fire({
        icon: "error",
        title: "예약 고객명을 입력하세요.",
      });
      return;
    }

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "고객 연락처를 입력하세요.",
      });
      return;
    }

    // phone은 숫자가 아닌 모든 문자는 제거하고 01000000000 형식으로 입력되어야 함
    const unFormattedPhone = phone.replace(/[^0-9]/g, "");
    if (unFormattedPhone.length !== 11) {
      Swal.fire({
        icon: "error",
        title: "올바른 전화번호를 입력하세요.",
      });
      return;
    }

    const data = {
      date,
      time,
      course_code: courseCode,
      course_name: course,
      name,
      phone: unFormattedPhone,
      type,
      type_name: typeName,
      person,
      hole,
      price,
      notes,
    };

    // 예약을 진행하시겠습니까?
    Swal.fire({
      title: "예약을 진행하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: `예약`,
      cancelButtonText: `취소`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/reservation", data);
        if (res.status === 200) {
          if (res.data.resultStatus === "success") {
            // 예약이 완료되었습니다. 예약 목록으로 이동할까요? 네, 아니오
            Swal.fire({
              icon: "success",
              title: "예약이 완료되었습니다. 예약 목록으로 이동할까요?",
              showCancelButton: true,
              confirmButtonText: `예약목록으로 가기`,
              cancelButtonText: `닫기`,
            }).then((result) => {
              btnCloseRef.current.click();
              props.onClose();

              if (result.isConfirmed) {
                navigate("/reservation");
              }
            });
          } else if (res.data.resultStatus === "fail") {
            Swal.fire({
              icon: "error",
              title: "예약에 실패하였습니다.",
              text: res.data.message,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "예약에 실패하였습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.reservation) {
      setDate(props.reservation.date);
      setTime(props.reservation.time);
      setCourse(props.reservation.course_name);
      setCourseCode(props.reservation.course_code);
      setPerson(props.reservation.person);
      setMinimumPerson(props.reservation.person);
      setPrice(props.reservation.price);
      setType(props.reservation.type);
      setTypeName(props.reservation.type_name);
      setHole(props.reservation.hole);
      setPrice(props.reservation.price);
      setNotes(props.reservation.notes);

      btnBookingRef.current.click();
    }
  }, [props.reservation]);

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      // setPhone(userData.phone);
    }
  }, [userData]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#bookingModal"
        ref={btnBookingRef}
      ></button>
      <div
        className="modal fade"
        id="bookingModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="bookingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">예약정보입력</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="booking-date" className="form-label">
                    예약 날짜: {date} {$convertHourFormat(time)}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-time" className="form-label">
                    예약 코스: {course}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-name" className="form-label">
                    타입: {typeName}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-time" className="form-label">
                    예약 인원
                  </label>

                  {/* select 사용.  props.booking?.person이 2이면, option은 2,3,4, props.booking?.person이 3이면 option은 3,4, props.booking?.person이 4이면 option은 4*/}
                  {minimumPerson === 2 ? (
                    <select
                      className="form-select"
                      id="booking-people"
                      aria-label="Select people"
                      value={person}
                      onChange={(e) => setPerson(e.target.value)}
                    >
                      <option value="2">2명</option>
                      <option value="3">3명</option>
                      <option value="4">4명</option>
                    </select>
                  ) : minimumPerson === 3 ? (
                    <select
                      className="form-select"
                      id="booking-people"
                      aria-label="Select people"
                      value={person}
                      onChange={(e) => setPerson(e.target.value)}
                    >
                      <option value="3">3명</option>
                      <option value="4">4명</option>
                    </select>
                  ) : (
                    <select
                      className="form-select"
                      id="booking-people"
                      aria-label="Select people"
                      value={person}
                      onChange={(e) => setPerson(e.target.value)}
                    >
                      <option value="4">4명</option>
                    </select>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="booking-name" className="form-label">
                    예약 고객명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="booking-name"
                    placeholder="예약 고객명을 입력하세요."
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-phone" className="form-label">
                    고객 연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="booking-phone"
                    placeholder="고객 연락처를 숫자만 입력하세요. (ex. 01012345678)"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                {/* 비고 - textarea, 500byte 이내 */}
                <div className="mb-3">
                  <label htmlFor="booking-notes" className="form-label">
                    비고
                  </label>
                  <textarea
                    className="form-control"
                    id="booking-notes"
                    rows={3}
                    maxLength={150}
                    placeholder="예약에 대한 비고사항을 입력하세요.(최대 150자)"
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  />
                </div>

                {/* <div className="mb-3">
                  <label className="form-label">부가서비스</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="booking-cart"
                        value={cartYn}
                        onChange={(e) => setCartYn(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="booking-cart"
                      >
                        카트
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="booking-caddy"
                        value={caddyYn}
                        onChange={(e) => setCaddyYn(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="booking-caddy"
                      >
                        캐디
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => reservation()}
              >
                예약완료
              </button>
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReservationModal;
