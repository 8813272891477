import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function Home() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const [ballPosition, setBallPosition] = useState({ x: 100, y: 500 });
  const [ballInHole, setBallInHole] = useState(false);

  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();
  const [showLogo, setShowLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [golfInfo, setGolfInfo] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const googleLoginRef = useRef();

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope:
        "profile_nickname, profile_image, account_email, name, phone_number",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    const sessionGolfInfo = JSON.parse(localStorage.getItem("golfInfo"));

    // console.log(resJson);

    const loginData = {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      name:
        resJson.kakao_account.name || resJson.kakao_account.profile.nickname,
      phone: resJson.kakao_account.phone_number || "",
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
    };

    if (sessionGolfInfo) {
      // console.log(sessionGolfInfo);
      loginData.golf_code = sessionGolfInfo.code;
      loginData.golf_name = sessionGolfInfo.name;
    }

    const res2 = await $post("/api/kakao-login", loginData);

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      checkLogin();

      navigate("/chat");
      // window.location.reload();
      // refresh page

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();
    // console.log(resJson);

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    // console.log(params.get("code"));
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      logoutWithKakao(res.accessToken);
    }
  };

  // get golf detail
  const getGolfDetail = async () => {
    // url에서 localhost:3000/haevichi 에서 haevichi가 골프 code임
    const code = window.location.pathname.split("/")[1];
    if (!code) {
      // 올바른 로그인 주소가 아닙니다.
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "올바른 로그인 주소가 아닙니다.",
      // });
      return;
    }

    const res = await $get(`/api/golfclub/${code}`);
    if (res.status === 200) {
      const golf = res.data[0];
      // console.log(golf);
      setGolfInfo(golf);
      localStorage.setItem("golfInfo", JSON.stringify(golf));
      // console.log(localStorage.getItem("golfInfo"));
    } else {
      // sessionStorage.removeItem("golfInfo");
      if (localStorage.getItem("golfInfo")) {
        localStorage.removeItem("golfInfo");
      }

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "존재하지 않는 골프장입니다.",
      });
    }
  };

  useEffect(() => {
    getGolfDetail();
    initKaKao();
    requestKaKaoInfo();
  }, []);

  return (
    <div className="container login">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              {golfInfo ? (
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      {golfInfo && (
                        <h3 className="fw-bold mb-3 text-nowrap">
                          {golfInfo.name}
                        </h3>
                      )}
                    </div>
                  </div>

                  <div className="col-12 text-center">
                    <img
                      src={`${appUrl}/assets/images/kakao-login-button.png`}
                      className="kakao-login-button"
                      onClick={loginWithKakao}
                    />
                  </div>
                </div>
              ) : (
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      골프장 로그인 URL로 접속하세요.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* copyright */}
      <div className="text-center fixed-bottom">
        <p className="text-white mb-0">
          <small>
            Copyright &copy; {currentYear}. 주식회사 이든골프마케팅. All rights
            reserved.
          </small>
        </p>
        <p className="text-white mb-0">
          <small>
            사업자번호: 147-86-02903 | 대표: 김지훈 | 주소: 제주특별자치도
            제주시 진남로 32, 지하1층
          </small>
        </p>
        <p className="text-white">
          {/* 개인정보처리방침, 이용약관 */}
          <small>
            <a
              href="https://iris-top-f7f.notion.site/2006f015bcfc478f86aca7dbe9219c2a"
              target="_blank"
              className="text-white"
            >
              개인정보처리방침
            </a>{" "}
            |{" "}
            <a
              href="https://iris-top-f7f.notion.site/22f80d8983db49ccb6ad75a274bdfe45"
              target="_blank"
              className="text-white"
            >
              이용약관
            </a>
          </small>
        </p>
      </div>
    </div>
  );
}

export default Home;
