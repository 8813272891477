import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $delete,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function InquiryReplyModal(props) {
  const { userData } = useAuth();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [inquiryDatetime, setInquiryDatetime] = useState("");
  const [status, setStatus] = useState("");
  const [reply, setReply] = useState("");
  const btnReplyRef = useRef(null);
  const btnCloseRef = useRef(null);

  const updateInquiry = async () => {
    if (!status) {
      // 처리상태를 선택하세요.
      Swal.fire({
        title: "처리상태를 선택하세요.",
        icon: "warning",
      });
      return;
    }

    // 정말 저장하시겠습니까?
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          id,
          status,
          reply,
        };
        const res = await $put(`/api/inquiry/${id}`, data);
        if (res.status === 200) {
          Swal.fire({
            title: "저장되었습니다.",
            icon: "success",
          }).then(() => {
            btnCloseRef.current.click();
            props.onClose();
          });
        } else {
          // 저장에 실패했습니다.
          Swal.fire({
            title: "저장에 실패했습니다.",
            icon: "error",
          });
        }
      }
    });
  };

  const deleteInquiry = async () => {
    // 정말 삭제하시겠습니까?
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/inquiry/${id}`);
        if (res.status === 200) {
          Swal.fire({
            title: "삭제되었습니다.",
            icon: "success",
          }).then(() => {
            btnCloseRef.current.click();
            props.onClose();
          });
        } else {
          // 삭제에 실패했습니다.
          Swal.fire({
            title: "삭제에 실패했습니다.",
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.inquiry) {
      setId(props.inquiry.id);
      setName(props.inquiry.name);
      setPhone(props.inquiry.phone);
      setNotes(props.inquiry.notes);
      setInquiryDatetime(props.inquiry.datetime);
      setStatus(props.inquiry.status);
      setReply(props.inquiry.reply);
      btnReplyRef.current.click();
    }
  }, [props.inquiry]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#replyModal"
        ref={btnReplyRef}
      ></button>
      <div
        className="modal fade"
        id="replyModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="replyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">별도 문의 내용</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="inquiry-name" className="form-label">
                    문의자명: {name}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    연락처: {phone}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-notes" className="form-label">
                    문의사항
                  </label>
                  <div className="formatted-text">{notes}</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-status" className="form-label">
                    처리상태
                  </label>
                  {/* select 미처리(N), 처리중(P), 처리완료(C) */}
                  <select
                    className="form-select"
                    id="inquiry-status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    disabled={userData.golfRoleLevel === 1}
                  >
                    <option value="N">미처리</option>
                    <option value="P">처리중</option>
                    <option value="C">처리완료</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-reply" className="form-label">
                    처리내용
                  </label>
                  {userData.golfRoleLevel === 1 ? (
                    <div className="formatted-text">{reply}</div>
                  ) : (
                    <textarea
                      className="
                      form-control
                      form-control-solid
                      h-auto
                      min-h-100px
                    "
                      id="inquiry-reply"
                      rows={6}
                      placeholder="처리 내용을 입력하세요."
                      value={reply || ""}
                      onChange={(e) => setReply(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              {userData.golfRoleLevel === 0 && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    updateInquiry();
                  }}
                >
                  저장
                </button>
              )}

              {id && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteInquiry();
                  }}
                >
                  삭제
                </button>
              )}
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default InquiryReplyModal;
