import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $delete,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function GolfclubModal(props) {
  const { userData } = useAuth();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [address, setAddress] = useState("");

  const [chatAnounce, setChatAnounce] = useState("");
  const btnGolfclubRef = useRef(null);
  const btnCloseRef = useRef(null);

  const addGolfclub = async () => {
    if (!name) {
      Swal.fire("알림", "골프장 이름을 입력하세요.", "warning");
      return;
    }
    if (!code) {
      Swal.fire("알림", "골프장 코드를 입력하세요.", "warning");
      return;
    }

    const data = {
      name: name,
      code: code,
      tel: tel,
      address: address,
      chat_anounce: chatAnounce,
    };

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "골프장을 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = "/api/golf";
        const res = await $post(url, data);
        if (res.status === 200) {
          Swal.fire({
            title: "골프장이 추가되었습니다.",
            icon: "success",
          }).then(() => {
            btnCloseRef.current.click();
            props.onClose();
          });
        } else {
          Swal.fire("알림", "골프장 추가에 실패했습니다.", "error");
        }
      }
    });
  };

  const updateGolfclub = async () => {
    if (!name) {
      Swal.fire("알림", "골프장 이름을 입력하세요.", "warning");
      return;
    }
    if (!code) {
      Swal.fire("알림", "골프장 코드를 입력하세요.", "warning");
      return;
    }

    const data = {
      id: id,
      name: name,
      code: code,
      tel: tel,
      address: address,
      chat_anounce: chatAnounce,
    };

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "골프장을 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const url = `/api/golf/${id}`;
        const res = await $put(url, data);
        if (res.status === 200) {
          Swal.fire({
            title: "골프장이 수정되었습니다.",
            icon: "success",
          }).then(() => {
            btnCloseRef.current.click();
            props.onClose();
          });
        } else {
          Swal.fire("알림", "골프장 수정에 실패했습니다.", "error");
        }
      }
    });
  };

  // const deleteGolfclub = async () => {
  //   Swal.fire({
  //     title: "골프장을 삭제하시겠습니까?",
  //     text: "골프장을 삭제하면 복구할 수 없습니다.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "삭제",
  //     cancelButtonText: "취소",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       // 관리자 비밀번호 확인
  //       const { value: password } = await Swal.fire({
  //         title: "관리자 비밀번호 확인",
  //         input: "password",
  //         inputLabel: "비밀번호",
  //         inputPlaceholder: "비밀번호를 입력하세요.",
  //         inputAttributes: {
  //           maxlength: 10,
  //           autocapitalize: "off",
  //           autocorrect: "off",
  //         },
  //         showCancelButton: true,
  //         confirmButtonText: "확인",
  //         cancelButtonText: "취소",
  //         inputValidator: (value) => {
  //           if (!value) {
  //             return "비밀번호를 입력하세요.";
  //           }
  //         },
  //       });

  //       if (password) {
  //         const res = await $delete(`/api/golf/${id}`, { password: password });
  //         if (res.status === 200) {
  //           Swal.fire({
  //             title: "골프장이 삭제되었습니다.",
  //             icon: "success",
  //           }).then(() => {
  //             btnCloseRef.current.click();
  //             props.onClose();
  //           });
  //         } else {
  //           Swal.fire("알림", "골프장 삭제에 실패했습니다.", "error");
  //         }
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    if (props.golfclub) {
      setId(props.golfclub.id);
      setName(props.golfclub.name);
      setTel(props.golfclub.tel);
      setCode(props.golfclub.code);
      setAddress(props.golfclub.address);
      setChatAnounce(props.golfclub.chat_anounce);
      btnGolfclubRef.current.click();
    } else {
      setId("");
      setName("");
      setTel("");
      setCode("");
      setAddress("");
      setChatAnounce("");
      btnGolfclubRef.current.click();
    }
  }, [props.golfclub]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#golfclubModal"
        ref={btnGolfclubRef}
      ></button>
      <div
        className="modal fade"
        id="golfclubModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="golfclubModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">골프장 정보</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="golfclub-name" className="form-label">
                    골프장 이름
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="golfclub-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                {/* 코드 */}
                <div className="mb-3">
                  <label htmlFor="golfclub-code" className="form-label">
                    골프장 고유 코드
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="golfclub-code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>

                {/* 연락처 */}
                <div className="mb-3">
                  <label htmlFor="golfclub-tel" className="form-label">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="golfclub-tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </div>
                {/* 주소 */}
                <div className="mb-3">
                  <label htmlFor="golfclub-address" className="form-label">
                    주소
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="golfclub-address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="booking-anounce" className="form-label">
                    AI 채팅 사용 안내 메세지
                  </label>
                  <textarea
                    className="
                      form-control
                      form-control-solid
                      h-auto
                      min-h-100px
                    "
                    id="booking-anounce"
                    rows={6}
                    placeholder="채팅 시작시 보여지는 사용 안내 메세지를 입력하세요."
                    value={chatAnounce}
                    onChange={(e) => setChatAnounce(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  id ? updateGolfclub() : addGolfclub();
                }}
              >
                {id ? "저장" : "추가"}
              </button>

              {/* {id && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteGolfclub();
                  }}
                >
                  삭제
                </button>
              )} */}
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default GolfclubModal;
