import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Menu from "../data/Menu.json";

const menus = Menu.menus;

function OffcanvasMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData } = useAuth();
  const btnOffcanvasCloseRef = useRef(null);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (userData) {
      const menus = Menu.menus.filter(
        (menu) =>
          menu.golf_role_level >= userData.golfRoleLevel ||
          menu.role_level >= userData.role_level
      );
      setMenus(menus);
    }
  }, [userData]);

  return (
    <div
      className="offcanvas offcanvas-start"
      tabIndex={-1}
      id="sidebarMenu"
      aria-labelledby="sidebarMenuLabel"
    >
      <div className="offcanvas-header">
        {userData && (
          <h5 className="offcanvas-title" id="sidebarMenuLabel">
            {userData.golfName}
          </h5>
        )}
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          ref={btnOffcanvasCloseRef}
        />
      </div>
      <div className="offcanvas-body d-flex flex-column">
        <div className="flex-grow-1">
          <ul className="nav flex-column text-white">
            {menus.map((menu, index) => (
              <li className="nav-item" key={`menu${index}`}>
                <a
                  className={
                    location.pathname === menu.path
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={() => {
                    // close offcanvas
                    navigate(menu.path);
                    setTimeout(() => {
                      btnOffcanvasCloseRef.current.click();
                    }, 100);
                  }}
                >
                  <i className={menu.icon}></i>
                  {menu.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {userData && (
          <div className="user-profile mt-auto">
            <a
              className="dropdown-toggle d-flex align-items-center text-white"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={userData.profile_image_url} alt="User Profile" />
              <div className="text-white">
                <div>{userData.name || userData.nickname}</div>
                <div>
                  <small>{userData.email}</small>
                </div>
              </div>
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="fas fa-sign-out-alt me-2"></i>로그아웃
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default OffcanvasMenu;
