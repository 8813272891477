import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function FAQ() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [theme, setTheme] = useState("light");
  const [inputMessage, setInputMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchFaqCategoryId, setSearchFaqCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [activeFaq, setActiveFaq] = useState({}); // [id, question, answer
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [faqCategoryId, setFaqCategoryId] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const btnCloseRef = useRef(null);
  const btnFaqUpdateModal = useRef(null);
  const btnFaqUpdateCloseRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getFaqList(true);
  };

  const deleteFaq = async (id) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 데이터는 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/faq/${id}`);
        if (res.status === 200) {
          Swal.fire("FAQ가 삭제되었습니다.", "", "success");
          getFaqList();
          btnCloseRef.current.click();
          btnFaqUpdateCloseRef.current.click();
        } else {
          Swal.fire("FAQ 삭제에 실패했습니다.", "", "error");
        }
      }
    });
  };

  const updateFaq = async () => {
    const res = await $put(`/api/faq/${activeFaq.id}`, {
      question: activeFaq.question,
      answer: activeFaq.answer,
      category_id: activeFaq.category_id,
    });
    if (res.status === 200) {
      setActiveFaq({});
      Swal.fire("FAQ가 수정되었습니다.", "", "success");
      getFaqList();
      btnCloseRef.current.click();
      btnFaqUpdateCloseRef.current.click();
    } else {
      Swal.fire("FAQ 수정에 실패했습니다.", "", "error");
    }
  };

  const addFaq = async () => {
    if (!faqQuestion || !faqAnswer) {
      Swal.fire("질문과 답변을 입력해주세요.", "", "error");
      return;
    }

    if (!faqCategoryId) {
      Swal.fire("카테고리를 선택해주세요.", "", "error");
      return;
    }

    if (!userData.golfCode) {
      Swal.fire("골프장 정보가 없습니다.", "", "error");
      return;
    }

    const res = await $post("/api/faq", {
      golf_code: userData.golfCode,
      question: faqQuestion,
      answer: faqAnswer,
      category_id: faqCategoryId,
    });
    if (res.status === 200) {
      Swal.fire("FAQ가 추가되었습니다.", "", "success");
      setFaqQuestion("");
      setFaqAnswer("");
      setFaqCategoryId("");
      getFaqList();
      btnCloseRef.current.click();
    }
  };

  const getFaqList = async (isPaging) => {
    if (!userData.golfCode) {
      Swal.fire("골프장 정보가 없습니다.", "", "error");
      return;
    }

    const tempPageNo = isPaging ? pageNo : 1;

    // const apiURL =
    //   searchText === ""
    //     ? `/api/faq/list/${pageNo}/${pageLimit}`
    //     : `/api/faq/list/${pageNo}/${pageLimit}?search=${encodeURIComponent(
    //         searchText
    //       )}`;

    const apiURL =
      searchText === "" && searchFaqCategoryId === ""
        ? `/api/faq/list/${tempPageNo}/${pageLimit}`
        : `/api/faq/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
            searchText
          )}&faqCategoryId=${searchFaqCategoryId}`;

    const res = await $get(apiURL);
    if (res.status === 200) {
      setFaqList(res.data.faqs);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  const getCategoryList = async () => {
    const res = await $get("/api/faq/category");
    if (res.status === 200) {
      setCategoryList(res.data);
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    getCategoryList();
  }, []);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-content-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a className="navbar-brand ms-3" onClick={() => navigate("/faq")}>
            FAQ 관리
          </a>
        </div>
      </nav>
      <div className="content-wrapper">
        {/* 추가 버튼, 오른쪽 정렬 */}
        <div className="d-flex justify-content-between mb-1">
          {/* input search */}
          <div className="input-group" style={{ maxWidth: "400px" }}>
            <select
              className="form-select"
              aria-label="Default select example"
              value={searchFaqCategoryId}
              onChange={(e) => setSearchFaqCategoryId(e.target.value)}
            >
              <option value=""></option>
              {categoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="form-control"
              placeholder="검색어를 입력하세요."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (e.nativeEvent.isComposing) {
                    return;
                  }
                  // if (e.target.value.trim() === "") {
                  //   return;
                  // }
                  getFaqList();
                }
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => getFaqList()}
            >
              <i className="fas fa-search" />
            </button>
          </div>
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#faqModal"
          >
            FAQ 추가
          </button>
        </div>
        {/* FAQ , accordion */}

        {/* table */}
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th style={{ minWidth: "240px" }}>질문</th>
                <th>답변</th>
                <th style={{ minWidth: "90px" }}>카테고리</th>
              </tr>
            </thead>
            <tbody>
              {faqList.map((faq) => (
                <tr key={faq.id}>
                  <td>
                    <a
                      className="link-modal"
                      onClick={() => {
                        setActiveFaq(faq);
                        btnFaqUpdateModal.current.click();
                      }}
                    >
                      {faq.question}
                    </a>
                  </td>
                  <td>
                    <div className="formatted-text">{faq.answer}</div>
                  </td>
                  <td>{faq.category_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mt-10">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {pageNo > 5 && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo - 1)}
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                )}

                {pageRange.map((page) => (
                  <li key={page}>
                    <a
                      className={
                        pageNo === page ? "page-link active" : "page-link"
                      }
                      onClick={(e) => setPageNo(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                {lastPage < totalPage && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo + 1)}
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {/* modal faq 추가 */}
      <div
        className="modal fade"
        id="faqModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="faqModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="faqModalLabel">
                FAQ 추가
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="question" className="form-label">
                  카테고리
                </label>
                {/* select */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={faqCategoryId}
                  onChange={(e) => setFaqCategoryId(e.target.value)}
                >
                  <option value="">카테고리를 선택하세요.</option>
                  {categoryList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="question" className="form-label">
                  질문
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="question"
                  value={faqQuestion}
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="answer" className="form-label">
                  답변
                </label>
                <textarea
                  className="form-control"
                  id="answer"
                  rows={10}
                  value={faqAnswer}
                  onChange={(e) => setFaqAnswer(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => addFaq()}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* modal update faq */}
      <button
        type="button"
        className="btn btn-primary"
        ref={btnFaqUpdateModal}
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#faqUpdateModal"
      ></button>
      <div
        className="modal fade"
        id="faqUpdateModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="faqUpdateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="faqUpdateModalLabel">
                FAQ 수정
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnFaqUpdateCloseRef}
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="question" className="form-label">
                  카테고리
                </label>
                {/* select */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={activeFaq.category_id}
                  onChange={(e) =>
                    setActiveFaq({ ...activeFaq, category_id: e.target.value })
                  }
                >
                  {categoryList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="question" className="form-label">
                  질문
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="question"
                  value={activeFaq.question}
                  onChange={(e) =>
                    setActiveFaq({ ...activeFaq, question: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="answer" className="form-label">
                  답변
                </label>
                <textarea
                  className="form-control"
                  id="answer"
                  rows={10}
                  value={activeFaq.answer}
                  onChange={(e) =>
                    setActiveFaq({ ...activeFaq, answer: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => updateFaq()}
              >
                저장
              </button>
              {/* 삭제 */}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteFaq(activeFaq.id)}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
