import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $put, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

function Dashboard() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [faqMonthlyTrend, setFaqMonthlyTrend] = useState(null);
  const [reservationMonthlyTrend, setReservationMonthlyTrend] = useState(null);
  const [userLoginMonthlyTrend, setUserLoginMonthlyTrend] = useState(null);
  const [statistics, setStatistics] = useState({
    reservation_this_month_count: 0,
    reservation_last_month_count: 0,
    faq_this_month_count: 0,
    faq_last_month_count: 0,
    login_this_month_count: 0,
    login_last_month_count: 0,
  });

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getFaqMonthlyTrend = async () => {
    const res = await $get("/api/report/faq-history/month");
    console.log(res);
    if (res.status === 200) {
      setFaqMonthlyTrend({
        series: [
          {
            name: "답변 완료",
            type: "column",
            data: res.data.map((item) => item.answer_count),
          },
          {
            name: "답변 미완료",
            type: "line",
            data: res.data.map((item) => item.no_answer_count),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.map((item) => item.date),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "답변 완료",
              },
            },
            {
              opposite: true,
              title: {
                text: "답변 미완료",
              },
            },
          ],
        },
      });
    }
  };

  const getReservationMonthlyTrend = async () => {
    const res = await $get("/api/report/reservation/month");
    console.log(res);
    if (res.status === 200) {
      setReservationMonthlyTrend({
        series: [
          {
            name: "예약 완료",
            type: "column",
            data: res.data.map((item) => item.complete_count),
          },
          {
            name: "예약 취소",
            type: "line",
            data: res.data.map((item) => item.cancel_count),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.map((item) => item.date),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "예약 완료",
              },
            },
            {
              opposite: true,
              title: {
                text: "예약 취소",
              },
            },
          ],
        },
      });
    }
  };

  const getUserLoginMonthlyTrend = async () => {
    const res = await $get("/api/report/login-history/month");
    console.log(res);
    if (res.status === 200) {
      setUserLoginMonthlyTrend({
        series: [
          {
            name: "사용자 접속",
            // type: "line",
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            curve: "straight",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          // labels: res.data.map((item) => item.date),
          xaxis: { categories: res.data.map((item) => item.date) },
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "사용자 접속",
              },
            },
          ],
        },
      });
    }
  };

  const getStatiscs = async () => {
    const res = await $get("/api/report/statistics");
    if (res.status === 200) {
      setStatistics(res.data);
    }
  };

  useEffect(() => {
    getStatiscs();
    getReservationMonthlyTrend();
    getFaqMonthlyTrend();
    getUserLoginMonthlyTrend();
  }, []);

  return (
    // localhost 이면
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a
            className="navbar-brand ms-3"
            onClick={() => navigate("/dashboard")}
          >
            현황 분석 대시보드
          </a>
        </div>
      </nav>
      <div className="content-wrapper">
        <section className="mb-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="card bg-success text-white">
                  <div className="card-body">
                    <h5 className="card-title">골프 예약 현황</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h1 className="mb-0">
                          {statistics.reservation_this_month_count || 0}
                        </h1>
                        <p className="text-white">이번달</p>
                      </div>
                      <div>
                        <h1 className="mb-0">
                          {statistics.reservation_last_month_count || 0}
                        </h1>
                        <p className="text-white">지난달</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="card bg-warning text-white">
                  <div className="card-body">
                    <h5 className="card-title">FAQ 처리 현황</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h1 className="mb-0">
                          {statistics.faq_this_month_count || 0}
                        </h1>
                        <p className="text-white">이번달</p>
                      </div>
                      <div>
                        <h1 className="mb-0">
                          {statistics.faq_last_month_count || 0}
                        </h1>
                        <p className="text-white">지난달</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="card bg-danger text-white">
                  <div className="card-body">
                    <h5 className="card-title">사용자 접속 현황</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h1 className="mb-0">
                          {statistics.login_this_month_count || 0}
                        </h1>
                        <p className="text-white">이번달</p>
                      </div>
                      <div>
                        <h1 className="mb-0">
                          {statistics.login_last_month_count || 0}
                        </h1>
                        <p className="text-white">지난달</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-4">
          <div className="container">
            <h3 className="">월별 골프 예약 현황</h3>
            {reservationMonthlyTrend !== null && (
              <Chart
                options={reservationMonthlyTrend.options}
                series={reservationMonthlyTrend.series}
                type="line"
                height={350}
              />
            )}
          </div>
        </section>
        <section className="mb-4">
          <div className="container">
            <h3 className="">월별 FAQ 대응 현황</h3>
            {faqMonthlyTrend !== null && (
              <Chart
                options={faqMonthlyTrend.options}
                series={faqMonthlyTrend.series}
                type="line"
                height={350}
              />
            )}
          </div>
        </section>
        <section className="mb-4">
          <div className="container">
            <h3 className="">월별 사용자 접속 현황</h3>
            {userLoginMonthlyTrend !== null && (
              <Chart
                options={userLoginMonthlyTrend.options}
                series={userLoginMonthlyTrend.series}
                type="line"
                height={350}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
