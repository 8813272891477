import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function UserModal(props) {
  const { userData } = useAuth();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [golfRoleLevel, setGolfRoleLevel] = useState(1);
  const [golfCode, setGolfCode] = useState("");
  const [registerDate, setRegisterDate] = useState("");
  const [notes, setNotes] = useState("");
  const btnCloseRef = useRef(null);
  const btnUserRef = useRef(null);

  const updateRole = async () => {
    // 권한을 변경하시겠습니까?
    Swal.fire({
      title: "사용자 권한 변경",
      text: "사용자 권한을 변경하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/user/golf`, {
          email: email,
          golf_role_level: golfRoleLevel,
          golf_code: golfCode,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "사용자 권한이 변경되었습니다.",
          }).then(() => {
            btnCloseRef.current.click();
            props.onList();
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.user) {
      setName(props.user.name);
      setPhone(props.user.phone);
      setEmail(props.user.email);
      setGolfRoleLevel(props.user.golf_role_level);
      setGolfCode(props.user.golf_code);
      setRegisterDate($dateTimezone(props.user.golf_create_datetime));
    }
  }, [props.user]);

  useEffect(() => {
    btnUserRef.current.click();
  }, []);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#userModal"
        ref={btnUserRef}
      ></button>
      <div
        className="modal fade"
        id="userModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="userModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">사용자 정보</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="user-name" className="form-label">
                    이름 : {name}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    연락처 : {phone}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    이메일 : {email}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    가입일 : {registerDate}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    사용 권한
                  </label>
                  {/* select, 일반사용자(1), 관리자(0) */}
                  {/* input group: select & button(변경) */}
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="user-role"
                      value={golfRoleLevel}
                      onChange={(e) => {
                        setGolfRoleLevel(e.target.value);
                      }}
                    >
                      <option value="1">일반 사용자</option>
                      <option value="0">관리자</option>
                    </select>
                    <button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => updateRole()}
                    >
                      변경
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserModal;
