import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $dateTimezone,
  $convertHourFormat,
  $convertNumberFormat,
  $convertDateKoreanFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ReservationDetailModal from "../component/ReservationDetailModal";

function ReservationAdmin() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reservationList, setReservationList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [activeReservation, setActiveReservation] = useState(null);
  const [golfclubInfo, setGolfclubInfo] = useState(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList();
  };

  const getList = async () => {
    setLoading(true);
    const res = await $get(`/api/reservation/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setReservationList(res.data.list);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  const checkCancelAvailable = (date) => {
    // date 형식 YYYYMMDD
    // 오늘 로컬 날짜와 비교해서 4일 이내면 취소 불가능, 취소 가능하면 true, 불가능하면 false
    const today = new Date();
    const targetDate = new Date(
      date.substr(0, 4),
      date.substr(4, 2) - 1,
      date.substr(6, 2)
    );
    const diff = (targetDate - today) / (1000 * 60 * 60 * 24);
    return diff >= 4;
  };

  const getGolfclubInfo = async () => {
    const res = await $get(`/api/golf/${userData.golfCode}`);
    if (res.status === 200) {
      const data = res.data[0];
      if (data && data.tel) {
        // 숫자를 제외한 모든 문자 제거
        data.tel2 = data.tel.replace(/[^0-9]/g, "");
      }
      setGolfclubInfo(data);
    }
  };

  const completeCancel = async (id) => {
    // Swal - 골프장 예약 취소 완료, 골프장 시스템에서 취소 처리가 되었습니까?
    Swal.fire({
      title: "골프장 예약 취소 완료",
      text: "골프장 시스템에서 취소 처리가 되었습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "네, 취소 상태로 변경할게요",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/reservation/${id}`, {
          status: "C",
        });

        if (res.status === 200) {
          Swal.fire("취소 완료", "예약이 취소되었습니다.", "success");
          getList();
        }
      }
    });
  };

  useEffect(() => {
    if (userData) {
      getGolfclubInfo();
    }
  }, [userData]);

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getInquiryList();
  //   }
  // }, [isLoggedIn]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a
            className="navbar-brand ms-3"
            onClick={() => navigate("/reservation-admin")}
          >
            예약 현황 관리
          </a>
          {/* <div className="d-flex align-items-center">
            <button className="btn btn-outline-secondary me-3">
              <i className="fas fa-bookmark" />
            </button>
          </div> */}
        </div>
      </nav>
      <div className="content-wrapper">
        <div className="alert alert-danger" role="alert">
          <strong>주의!</strong> 예약 취소의 경우 실제 골프장 시스템에서 예약을
          취소한 후 본 페이지에서도 취소 처리를 해주셔야 합니다.
        </div>

        {/* table */}
        <div className="d-none d-md-block table-responsive">
          <table className="table table-bordered table-hover table-striped text-nowrap">
            <thead>
              <tr>
                <th>예약날짜</th>
                <th>예약시간</th>
                <th>코스</th>
                <th>타입</th>
                <th>예약인원</th>
                <th>금액(1인기준)</th>
                <th>예약자명</th>
                <th>전화번호</th>
                <th>상태</th>
                <th>예약요청일시</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reservationList.map((reservation) => (
                <tr key={reservation.id}>
                  <td>{$convertDateKoreanFormat(reservation.date)}</td>
                  <td>{$convertHourFormat(reservation.time)}</td>
                  <td>{reservation.course_name}</td>
                  <td>{reservation.type_name}</td>
                  <td>{reservation.person}</td>
                  <td>{$convertNumberFormat(reservation.price)}</td>
                  <td>{reservation.name}</td>
                  <td>{reservation.phone}</td>
                  <td>
                    {reservation.status === "Y"
                      ? "예약완료"
                      : reservation.status === "C"
                      ? "예약취소"
                      : ""}
                  </td>
                  <td>{$dateTimezone(reservation.create_datetime)}</td>
                  <td>
                    {reservation.status === "Y" &&
                    checkCancelAvailable(reservation.date) ? (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          completeCancel(reservation.id);
                        }}
                      >
                        골프장취소완료
                      </button>
                    ) : reservation.status === "Y" &&
                      !checkCancelAvailable(reservation.date) ? (
                      "취소불가"
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* 모바일 sm 사이즈인 경우는 card 형태로 예약 목록 보여주기 */}
        <div className="d-block d-md-none">
          {reservationList.map((reservation) => (
            <div className="card mb-3" key={reservation.id}>
              <div className="card-body">
                <h5 className="card-title">
                  [{reservation.course_name}]{" "}
                  {$convertDateKoreanFormat(reservation.date)}{" "}
                  {$convertHourFormat(reservation.time)}
                </h5>
                <p className="card-text">
                  예약자: {reservation.name} (Tel. {reservation.phone})
                </p>
                <p className="card-text">
                  예약인원: {reservation.person}인 | {reservation.type_name}
                </p>
                <p className="card-text">
                  {reservation.status === "Y"
                    ? "예약완료"
                    : reservation.status === "C"
                    ? "예약취소"
                    : ""}
                </p>
                <p className="card-text">
                  {reservation.create_datetime}{" "}
                  {reservation.status === "Y" &&
                  checkCancelAvailable(reservation.date) ? (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        completeCancel(reservation.id);
                      }}
                    >
                      골프장취소완료
                    </button>
                  ) : reservation.status === "Y" &&
                    !checkCancelAvailable(reservation.date) ? (
                    "취소불가"
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* pagination */}
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mt-10">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {pageNo > 5 && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo - 1)}
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                )}

                {pageRange.map((page) => (
                  <li key={page}>
                    <a
                      className={
                        pageNo === page ? "page-link active" : "page-link"
                      }
                      onClick={(e) => setPageNo(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                {lastPage < totalPage && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo + 1)}
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {showReservationModal && (
        <ReservationDetailModal
          reservation={activeReservation}
          onClose={() => {
            setActiveReservation(null);
            setShowReservationModal(false);
            getList();
          }}
        />
      )}
    </div>
  );
}

export default ReservationAdmin;
