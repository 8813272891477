import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertHourFormat,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function ReservationDetailModal(props) {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const [id, setId] = useState(0);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [course, setCourse] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [person, setPerson] = useState(2); // 2, 3, 4 중 하나
  const [minimumPerson, setMinimumPerson] = useState(2);
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [hole, setHole] = useState("");
  // const [cartYn, setCartYn] = useState(false);
  // const [caddyYn, setCaddyYn] = useState(false);
  const [notes, setNotes] = useState("");
  const btnBookingRef = useRef(null);
  const btnCloseRef = useRef(null);

  const cancelReservation = async () => {
    // 정말 취소하시겠습니까? 예약 취소 규정을 꼭 확인하세요.
    Swal.fire({
      title: "정말 취소하시겠습니까?",
      text: "예약 취소 규정을 꼭 확인하세요.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "네, 예약을 취소합니다.",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post(`/api/reservation/cancel/${id}`, {
          date: date,
          time: time,
          course_code: courseCode,
        });

        if (res.status === 200) {
          if (res.data.resultStatus === "success") {
            Swal.fire({
              icon: "success",
              title: "예약이 취소되었습니다.",
              showConfirmButton: false,
              timer: 1500,
            });
            btnCloseRef.current.click();
            props.onClose();
          } else if (res.data.resultStatus === "fail") {
            Swal.fire({
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "예약취소에 실패했습니다.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (props.reservation) {
      setId(props.reservation.id);
      setDate(props.reservation.date);
      setTime(props.reservation.time);
      setCourse(props.reservation.course_name);
      setCourseCode(props.reservation.course_code);
      setPerson(props.reservation.person);
      setPhone(props.reservation.phone);
      setName(props.reservation.name);
      setTypeName(props.reservation.type_name);
      setNotes(props.reservation.notes);
      btnBookingRef.current.click();
    }
  }, [props.reservation]);

  useEffect(() => {}, [userData]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#bookingModal"
        ref={btnBookingRef}
      ></button>
      <div
        className="modal fade"
        id="bookingModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="bookingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">예약정보</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="booking-date" className="form-label">
                    예약 날짜: {date} {$convertHourFormat(time)}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-time" className="form-label">
                    예약 코스: {course}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-name" className="form-label">
                    타입: {typeName}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-time" className="form-label">
                    예약 인원: {person}명
                  </label>
                </div>

                <div className="mb-3">
                  <label htmlFor="booking-name" className="form-label">
                    예약 고객명: {name}
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="booking-phone" className="form-label">
                    고객 연락처: {phone}
                  </label>
                </div>
                {/* 비고 - notes */}
                <div className="mb-3">
                  <label htmlFor="booking-notes" className="form-label">
                    비고
                  </label>
                  <textarea
                    className="form-control"
                    id="booking-notes"
                    rows={3}
                    value={notes}
                    readOnly
                  />
                </div>
              </div>
              {/* warning - 취소 규정 */}
              <div className="alert alert-danger" role="alert">
                <strong>예약 취소 규정</strong>
                <br />
                <p>
                  취소가능일은 주중 내장의 경우 내장일의 3일전까지 무료 취소가
                  가능하고 주말 내장의 경우 4일전까지 무료 취소 가능합니다.
                </p>{" "}
                <ul>
                  <li>3일전 취소 시 팀별 그린피의 10%(주말)</li>
                  <li>2일전 취소 시 팀별 그린피의 10%</li>
                  <li>1일전 취소 : 팀별 그린피의 20%</li>
                  <li>내장 당일 및 노쇼 : 팀별 그린피 30% 부과됩니다.</li>
                </ul>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => cancelReservation()}
              >
                예약취소
              </button>
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReservationDetailModal;
