import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $put, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import CategoryModal from "../component/CategoryModal";

function FaqCategory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const getCategoryList = async () => {
    setLoading(true);
    const res = await $get("/api/faq/category");
    if (res.status === 200) {
      setCategoryList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    // 권한 체크
    if (userData && userData.golfRoleLevel === 1) {
      navigate("/chat");
    }

    getCategoryList();
  }, [userData]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a className="navbar-brand ms-3" onClick={() => navigate("/terms")}>
            공통 용어 관리
          </a>
          {/* <div className="d-flex align-items-center">
            <button className="btn btn-outline-secondary me-3">
              <i className="fas fa-bookmark" />
            </button>
          </div> */}
        </div>
      </nav>
      <div className="content-wrapper">
        {/* button 추가 */}
        <div className="d-flex justify-content-end mb-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              setActiveCategory(null);
              setShowCategoryModal(true);
            }}
          >
            추가
          </button>
        </div>
        {/* table */}
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th>카테고리명</th>
              </tr>
            </thead>
            <tbody>
              {categoryList.map((category) => (
                <tr key={category.id}>
                  <td>
                    <a
                      onClick={() => {
                        setActiveCategory(category);
                        setShowCategoryModal(true);
                      }}
                      className="link-modal"
                    >
                      {category.category_name}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showCategoryModal && (
        <CategoryModal
          category={activeCategory}
          onClose={() => {
            setActiveCategory(null);
            setShowCategoryModal(false);
            getCategoryList();
          }}
        />
      )}
    </div>
  );
}

export default FaqCategory;
