import "./App.css";

import React, { useState } from "react";
import Sidebar from "./layout/Sidebar";
import OffcanvasMenu from "./layout/OffcanvasMenu";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import Reservation from "./pages/Reservation";
import ReservationHistory from "./pages/ReservationHistory";
import ReservationAdmin from "./pages/ReservationAdmin";
import FAQ from "./pages/FAQ";
import FaqCategory from "./pages/FaqCategory";
import FaqHistory from "./pages/FaqHistory";
import Inquiry from "./pages/Inquiry";
import InquiryAdmin from "./pages/InquiryAdmin";
import Golfclub from "./pages/Golfclub";
import Terms from "./pages/Terms";
import Profile from "./pages/Profile";
import UserList from "./pages/UserList";
import TokenUsage from "./pages/TokenUsage";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <div className="container-fluid p-0">
            <div className="row g-0">
              <Sidebar />
              <Routes>
                {/* <Route path="/" element={<Test />} /> */}
                <Route path="/" element={<Home />} />
                <Route path="/haevichi" element={<Home />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/401" element={<NotAuthorized />} />
                <Route path="/500" element={<InternalServerError />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/reservation" element={<Reservation />} />
                <Route
                  path="/reservation-admin"
                  element={<ReservationAdmin />}
                />
                <Route
                  path="/reservation-history"
                  element={<ReservationHistory />}
                />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/faq-category" element={<FaqCategory />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/faq-history" element={<FaqHistory />} />
                <Route path="/inquiry" element={<Inquiry />} />
                <Route path="/inquiry-admin" element={<InquiryAdmin />} />
                <Route path="/golfclub" element={<Golfclub />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/userlist" element={<UserList />} />
                <Route path="/token-usage" element={<TokenUsage />} />
                <Route path="/profile" element={<Profile />} />
              </Routes>
            </div>
            <OffcanvasMenu />
          </div>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
