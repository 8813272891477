import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $delete,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function CategoryModal(props) {
  const { userData } = useAuth();
  const [id, setId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const btnCategoryRef = useRef(null);
  const btnCloseRef = useRef(null);

  const addCategory = async () => {
    if (!categoryName) {
      Swal.fire("알림", "카테고리명을 입력하세요.", "warning");
      return;
    }

    const data = {
      category_name: categoryName,
    };
    const url = "/api/faq/category";
    const res = await $post(url, data);
    if (res.status === 200) {
      Swal.fire({
        title: "카테고리가 추가되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "카테고리명 추가에 실패했습니다.", "error");
    }
  };

  const updateCategory = async () => {
    if (!categoryName) {
      Swal.fire("알림", "카테고리명을 입력하세요.", "warning");
      return;
    }
    const data = {
      id: id,
      category_name: categoryName,
    };
    const url = `/api/faq/category/${id}`;
    const res = await $put(url, data);
    if (res.status === 200) {
      Swal.fire({
        title: "카테고리명이 수정되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "카테고리명 수정에 실패했습니다.", "error");
    }
  };

  const deleteCategory = async (id) => {
    // 카테고리 목록을 가져와서 해당 카테고리를 가진 faq의 카테고리가 있는지 확인하고 있다면 삭제 불가
    const categoryList = await $get("/api/faq/category");
    if (categoryList.data.some((category) => category.id === id)) {
      Swal.fire(
        "알림",
        "해당 카테고리에 속한 FAQ가 존재합니다. FAQ를 먼저 삭제해주세요.",
        "error"
      );
      btnCloseRef.current.click();
      props.onClose();
      return;
    }

    const url = `/api/faq/category/${id}`;
    const res = await $delete(url);
    if (res.status === 200) {
      Swal.fire({
        title: "카테고리가 삭제되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "카테고리 삭제에 실패했습니다.", "error");
    }
  };

  useEffect(() => {
    if (props.category) {
      setId(props.category.id);
      setCategoryName(props.category.category_name);

      btnCategoryRef.current.click();
    } else {
      setId(null);
      setCategoryName("");
      btnCategoryRef.current.click();
    }
  }, [props.category]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#termModal"
        ref={btnCategoryRef}
      ></button>
      <div
        className="modal fade"
        id="termModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="termModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">FAQ 카테고리</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="category" className="form-label">
                    카테고리명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="category"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (id) {
                    updateCategory();
                  } else {
                    addCategory();
                  }
                }}
              >
                {id ? "저장" : "추가"}
              </button>
              {id && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    Swal.fire({
                      title: "알림",
                      text: "카테고리를 삭제하시겠습니까?",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonText: "삭제",
                      cancelButtonText: "취소",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteCategory(id);
                      }
                    });
                  }}
                >
                  삭제
                </button>
              )}
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryModal;
