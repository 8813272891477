import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $put, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import InquiryReplyModal from "../component/InquiryReplyModal";

function Inquiry() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [activeInquiry, setActiveInquiry] = useState(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getInquiryList();
  };

  const getInquiryList = async () => {
    setLoading(true);
    const res = await $get(`/api/inquiry/user/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setInquiryList(res.data.inquiryList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getInquiryList();
  //   }
  // }, [isLoggedIn]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a className="navbar-brand ms-3" onClick={() => navigate("/inquiry")}>
            별도 문의
          </a>
          {/* <div className="d-flex align-items-center">
            <button className="btn btn-outline-secondary me-3">
              <i className="fas fa-bookmark" />
            </button>
          </div> */}
        </div>
      </nav>
      <div className="content-wrapper">
        {/* table */}
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped text-nowrap">
            <thead>
              <tr>
                <th style={{ width: "160px" }}>문의일시</th>
                <th style={{ width: "100px" }}>이름</th>
                <th style={{ width: "140px" }}>연락처</th>
                <th>문의내용</th>
                <th style={{ width: "70px" }}>처리여부</th>
              </tr>
            </thead>
            <tbody>
              {inquiryList.map((inquiry) => (
                <tr key={inquiry.id}>
                  <td>{$dateTimezone(inquiry.create_datetime)}</td>
                  <td>{inquiry.name}</td>
                  <td>{inquiry.phone}</td>
                  <td>{inquiry.notes}</td>
                  <td>
                    <a
                      className="link-modal"
                      onClick={() => {
                        setActiveInquiry(inquiry);
                        setShowInquiryModal(true);
                      }}
                    >
                      {inquiry.status === "N"
                        ? "미처리"
                        : inquiry.status === "P"
                        ? "처리중"
                        : inquiry.status === "C"
                        ? "처리완료"
                        : ""}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mt-10">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {pageNo > 5 && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo - 1)}
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                )}

                {pageRange.map((page) => (
                  <li key={page}>
                    <a
                      className={
                        pageNo === page ? "page-link active" : "page-link"
                      }
                      onClick={(e) => setPageNo(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                {lastPage < totalPage && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo + 1)}
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {showInquiryModal && (
        <InquiryReplyModal
          inquiry={activeInquiry}
          onClose={() => {
            setActiveInquiry(null);
            setShowInquiryModal(false);
            getInquiryList();
          }}
        />
      )}
    </div>
  );
}

export default Inquiry;
