import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $delete, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function Profile() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);

  const deleteAccount = async () => {
    Swal.fire({
      title: "정말로 탈퇴하시겠습니까?",
      text: "회원 탈퇴 시 모든 정보가 삭제됩니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "네",
      cancelButtonText: "아니요",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const res = await $delete("/api/user");
        setLoading(false);
        console.log(res);
        if (res.status === 200) {
          logout();
        } else {
          Swal.fire({
            title: "회원 탈퇴",
            text: res.data.message,
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {}, [userData]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a className="navbar-brand ms-3" onClick={() => navigate("/profile")}>
            프로필
          </a>
        </div>
      </nav>
      <div className="content-wrapper">
        <section className="mb-4">
          <div className="container">
            <div className="card mb-4 shadow-sm">
              <div className="card-body">
                <div className="mb-4">
                  <h5 className="mb-0">회원 정보</h5>
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">이름</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userData?.name}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">연락처</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userData?.phone}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label">이메일</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userData?.email}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-danger bg-danger bg-opacity-10 mb-4 shadow-sm">
              <div className="card-body p-lg-5">
                <div className="mb-4">
                  <h5 className="mb-0">회원 탈퇴</h5>
                </div>
                <ul className="list-unstyled mb-3">
                  <li className="mb-2">1. 모든 정보가 영구히 삭제됩니다.</li>
                  <li className="mb-2">
                    2. 삭제된 데이터는 복구가 불가능합니다.
                  </li>
                </ul>

                <div className="mt-3">
                  <a
                    onClick={() => {
                      deleteAccount();
                    }}
                    className="btn btn-danger"
                  >
                    회원 탈퇴
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* danger 회원 탈퇴 */}
        </section>
      </div>
    </div>
  );
}

export default Profile;
