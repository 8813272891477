import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $put, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import UserModal from "../component/UserModal";

function UserList() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [showUserModal, setShowUserModal] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList(true);
  };

  const getList = async (isPaging) => {
    setLoading(true);
    const tempPageNo = isPaging ? pageNo : 1;
    const url = searchText
      ? `/api/user/list/${tempPageNo}/${pageLimit}?search=${encodeURIComponent(
          searchText
        )}`
      : `/api/user/list/${tempPageNo}/${pageLimit}`;
    const res = await $get(url);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    // 권한 체크
    if (userData && userData.golfRoleLevel === 1) {
      navigate("/chat");
    }
  }, [userData]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a
            className="navbar-brand ms-3"
            onClick={() => navigate("/userlist")}
          >
            사용자 관리
          </a>
          {/* <div className="d-flex align-items-center">
            <button className="btn btn-outline-secondary me-3">
              <i className="fas fa-bookmark" />
            </button>
          </div> */}
        </div>
      </nav>
      <div className="content-wrapper">
        {/* 조회 input group - search and button */}
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="이메일 또는 이름 또는 전화번호를 입력하세요."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (e.nativeEvent.isComposing) {
                  return;
                }
                // if (e.target.value.trim() === "") {
                //   return;
                // }
                getList();
              }
            }}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => getList()}
          >
            <i className="fas fa-search" />
          </button>
        </div>

        {/* table */}
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th>이메일</th>
                <th>이름</th>
                <th>전화번호</th>
                <th>역할</th>
                <th>가입일</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user) => (
                <tr key={user.id}>
                  <td>
                    <a
                      className="link-modal"
                      onClick={() => {
                        setActiveUser(user);
                        setShowUserModal(true);
                      }}
                    >
                      {user.email}
                    </a>
                  </td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>
                    {user.golf_role_level === 1 ? "일반 사용자" : "관리자"}
                  </td>
                  <td>{$dateTimezone(user.golf_create_datetime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mt-10">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {pageNo > 5 && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo - 1)}
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                )}

                {pageRange.map((page) => (
                  <li key={page}>
                    <a
                      className={
                        pageNo === page ? "page-link active" : "page-link"
                      }
                      onClick={(e) => setPageNo(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                {lastPage < totalPage && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo + 1)}
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {showUserModal && (
        <UserModal
          user={activeUser}
          onClose={() => {
            setShowUserModal(false);
            setActiveUser(null);
          }}
          onList={() => {
            setShowUserModal(false);
            setActiveUser(null);
            getList(true);
          }}
        />
      )}
    </div>
  );
}

export default UserList;
