import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post, $put, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function FaqHistory() {
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [answerYn, setAnswerYn] = useState("");

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    getList();
  };

  const getList = async () => {
    setLoading(true);
    const apiURL =
      answerYn === ""
        ? `/api/faq/${pageNo}/${pageLimit}`
        : `/api/faq/${pageNo}/${pageLimit}?answerYn=${encodeURIComponent(
            answerYn
          )}`;

    const res = await $get(apiURL);
    if (res.status === 200) {
      setFaqList(res.data.faqs);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }

    setLoading(false);
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    // 권한 체크
    if (userData && userData.golfRoleLevel === 1) {
      navigate("/chat");
    }
  }, [userData]);

  return (
    <div className="col-12 col-lg-9 col-xl-10 main-content">
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid flex justify-space-between">
          <button
            className="btn btn-outline-secondary d-lg-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
          >
            <i className="fas fa-bars" />
          </button>
          <a
            className="navbar-brand ms-3"
            onClick={() => navigate("/faq-history")}
          >
            FAQ 히스토리
          </a>
          {/* <div className="d-flex align-items-center">
            <button className="btn btn-outline-secondary me-3">
              <i className="fas fa-bookmark" />
            </button>
          </div> */}
        </div>
      </nav>
      <div className="content-wrapper">
        <div className="d-flex justify-content-between mb-1">
          {/* select: 전체, 답변 완료, 답변 미완료 */}
          <div className="d-flex">
            <select
              className="form-select me-2"
              style={{ width: "150px" }}
              value={answerYn}
              onChange={(e) => setAnswerYn(e.target.value)}
            >
              <option value="">전체</option>
              <option value="Y">답변 완료</option>
              <option value="N">답변 미완료</option>
            </select>
            <button
              className="btn btn-outline-secondary"
              onClick={() => changePagination()}
            >
              검색
            </button>
          </div>
        </div>
        {/* table */}
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th style={{ width: "170px" }}>문의일시</th>
                <th style={{ width: "100px" }}>이메일</th>
                <th style={{ width: "80px" }}>답변여부</th>
                <th style={{ width: "140px" }}>질문</th>
                <th>대답</th>
              </tr>
            </thead>
            <tbody>
              {faqList.map((faq) => (
                <tr key={faq.id}>
                  <td>{$dateTimezone(faq.create_datetime)}</td>
                  <td>{faq.email}</td>
                  <td>{faq.answer_yn === "N" ? "미완료" : "완료"}</td>
                  <td>{faq.question}</td>
                  <td>{faq.answer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        {totalPage > 1 && (
          <div className="d-flex justify-content-center mt-10">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {pageNo > 5 && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo - 1)}
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                )}

                {pageRange.map((page) => (
                  <li key={page}>
                    <a
                      className={
                        pageNo === page ? "page-link active" : "page-link"
                      }
                      onClick={(e) => setPageNo(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                {lastPage < totalPage && (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => setPageNo(pageNo + 1)}
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}

export default FaqHistory;
