import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $delete,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function TermModal(props) {
  const { userData } = useAuth();
  const [id, setId] = useState("");
  const [terminology, setTerminology] = useState("");
  const [description, setDescription] = useState("");
  const [synonym, setSynonym] = useState("");
  const btnTermRef = useRef(null);
  const btnCloseRef = useRef(null);

  const addTerm = async () => {
    if (!terminology) {
      Swal.fire("알림", "용어를 입력하세요.", "warning");
      return;
    }
    if (!description) {
      Swal.fire("알림", "설명을 입력하세요.", "warning");
      return;
    }

    const data = {
      terminology: terminology,
      description: description,
      synonym: synonym,
    };
    const url = "/api/terms";
    const res = await $post(url, data);
    if (res.status === 200) {
      Swal.fire({
        title: "용어가 추가되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "용어 추가에 실패했습니다.", "error");
    }
  };

  const updateTerm = async () => {
    if (!terminology) {
      Swal.fire("알림", "용어를 입력하세요.", "warning");
      return;
    }
    if (!description) {
      Swal.fire("알림", "설명을 입력하세요.", "warning");
      return;
    }
    const data = {
      id: id,
      terminology: terminology,
      description: description,
      synonym: synonym,
    };
    const url = `/api/terms/${id}`;
    const res = await $put(url, data);
    if (res.status === 200) {
      Swal.fire({
        title: "용어가 수정되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "용어 수정에 실패했습니다.", "error");
    }
  };

  const deleteTerm = async (id) => {
    const url = `/api/terms/${id}`;
    const res = await $delete(url);
    if (res.status === 200) {
      Swal.fire({
        title: "용어가 삭제되었습니다.",
        icon: "success",
      }).then(() => {
        btnCloseRef.current.click();
        props.onClose();
      });
    } else {
      Swal.fire("알림", "용어 삭제에 실패했습니다.", "error");
    }
  };

  useEffect(() => {
    if (props.term) {
      setId(props.term.id);
      setTerminology(props.term.terminology);
      setDescription(props.term.description);
      setSynonym(props.term.synonym);
      btnTermRef.current.click();
    } else {
      setId(null);
      setTerminology("");
      setDescription("");
      setSynonym("");
      btnTermRef.current.click();
    }
  }, [props.term]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#termModal"
        ref={btnTermRef}
      ></button>
      <div
        className="modal fade"
        id="termModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="termModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">공통 용어</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="terminology" className="form-label">
                    용어
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="terminology"
                    value={terminology}
                    onChange={(e) => setTerminology(e.target.value)}
                  />
                </div>
                {/* 코드 */}
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    설명
                  </label>
                  <textarea
                    className="
                      form-control
                      form-control-solid
                      h-auto
                      min-h-100px
                    "
                    id="booking-notes"
                    rows={6}
                    placeholder="용어에 대한 설명을 입력하세요."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                {/* 연락처 */}
                <div className="mb-3">
                  <label htmlFor="synonym" className="form-label">
                    동의어
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="synonym"
                    value={synonym}
                    onChange={(e) => setSynonym(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (id) {
                    updateTerm();
                  } else {
                    addTerm();
                  }
                }}
              >
                {id ? "저장" : "추가"}
              </button>
              {id && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    Swal.fire({
                      title: "알림",
                      text: "용어를 삭제하시겠습니까?",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonText: "삭제",
                      cancelButtonText: "취소",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteTerm(id);
                      }
                    });
                  }}
                >
                  삭제
                </button>
              )}
            </div>
            {/* Modal: Footer */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TermModal;
