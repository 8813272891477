// AuthProvider.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";
import { $get } from "../utils/common";
import { useTranslation } from "react-i18next";
import Menu from "../data/Menu.json";

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  const login = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
    // console.log(data);
    localStorage.removeItem("golfInfo");
    // console.log(data);
    // i18n.changeLanguage(data.lang);
    // navigate("/work");
    // setTimeout(() => {
    //   navigate("/work");
    // }, 1000);
  };

  // const loginByGoogle = (data) => {
  //   setIsLoggedIn(true);
  //   setUserData(data);
  //   i18n.changeLanguage(data.lang);
  //   // navigate("/work");
  //   console.log("loginByGoogle");
  //   setTimeout(() => {
  //     navigate("/work");
  //   }, 1000);
  // };

  const logout = async () => {
    const golfCode = userData.golfCode || "";
    setIsLoggedIn(false);
    setUserData(null);
    const res = (await $get("/api/logout")).data;
    if (res.success) {
      // console.log("logout success");
      // localStorage.removeItem("golfInfo");
      localStorage.clear();
      // setTimeout(() => {
      navigate(`/${golfCode}`);
      // refresh page
      window.location.reload();
      // }, 1000);
    }
  };

  const checkLogin = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      const user = await $get(`/api/user`);
      login(user.data);

      // console.log("checkLogin", user.data);

      return true;

      // if (user.data.active_yn === "N") {
      //   navigate("/waiting-list");
      // }
    } else {
      setIsLoggedIn(false);
      setUserData(null);
      navigate("/");
      return false;
    }
  };

  useEffect(() => {
    // console.log(location.pathname);
    if (location.pathname !== "/") {
      if (
        Menu.menus.filter((menu) => menu.path === location.pathname).length > 0
      ) {
        checkLogin();
        // console.log("checkLogin");
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      if (
        Menu.menus.filter((menu) => menu.path === location.pathname).length > 0
      ) {
        checkLogin();
      }
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userData,
        setUserData,
        login,
        logout,
        checkLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
