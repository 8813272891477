import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $post, $dateTimezone } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import Menu from "../data/Menu.json";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, logout, userData } = useAuth();
  const [menus, setMenus] = useState([]);
  // const [golfInfo, setGolfInfo] = useState(null);
  const checkSidebar = () => {
    return Menu.menus.filter((menu) => menu.path === location.pathname).length;
  };

  useEffect(() => {
    if (userData) {
      const menus = Menu.menus.filter(
        (menu) =>
          menu.golf_role_level >= userData.golfRoleLevel ||
          menu.role_level >= userData.role_level
      );
      setMenus(menus);
    }
  }, [userData]);

  return checkSidebar() ? (
    <div className="col-lg-3 col-xl-2 d-none d-lg-flex sidebar">
      <div className="sidebar-content">
        {userData && <h4 className="text-center mb-4">{userData.golfName}</h4>}

        <ul className="nav flex-column">
          {menus.map((menu, index) => (
            <li className="nav-item" key={`menu${index}`}>
              <a
                className={
                  location.pathname === menu.path
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  navigate(menu.path);
                }}
              >
                <i className={menu.icon}></i>
                {menu.title}
              </a>
            </li>
          ))}
          {/* <li className="nav-item">
            <a
              className={
                location.pathname === "/reservation"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => {
                navigate("/reservation");
              }}
            >
              <i className="fa-solid fa-receipt me-2"></i>
              예약 현황
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/faq" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                navigate("/faq");
              }}
            >
              <i className="fa-solid fa-clipboard-question me-2"></i>
              FAQ 관리
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/faq-history"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => {
                navigate("/faq-history");
              }}
            >
              <i className="fa-solid fa-clipboard-question me-2"></i>
              FAQ History
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/inquiry"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => {
                navigate("/inquiry");
              }}
            >
              <i className="fa-solid fa-person-circle-question me-2"></i>
              별도 문의 관리
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/userlist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => {
                navigate("/userlist");
              }}
            >
              <i className="fa fa-users me-2" />
              사용자 관리
            </a>
          </li> */}
        </ul>
      </div>
      {userData && (
        <div className="user-profile mt-auto">
          <a
            className="dropdown-toggle d-flex align-items-center text-white"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={userData.profile_image_url} alt="User Profile" />
            <div className="text-white">
              <div>{userData.name || userData.nickname}</div>
              <div>
                <small>{userData.email}</small>
              </div>
            </div>
          </a>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a
                className="dropdown-item"
                onClick={() => {
                  logout();
                }}
              >
                <i className="fas fa-sign-out-alt me-2"></i>로그아웃
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

export default Sidebar;
