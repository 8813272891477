import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

function InquiryModal(props) {
  const { userData } = useAuth();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const btnCloseRef = useRef(null);
  const btnInquiryRef = useRef(null);

  const saveInquiry = async () => {
    if (!name) {
      // 문의자명을 입력하세요.
      Swal.fire({
        title: "문의자명을 입력하세요.",
        icon: "warning",
      });
      return;
    }

    if (!phone) {
      // 연락처를 입력하세요.
      Swal.fire({
        title: "연락처를 입력하세요.",
        icon: "warning",
      });
      return;
    }

    if (!notes) {
      // 문의사항을 입력하세요.
      Swal.fire({
        title: "문의사항을 입력하세요.",
        icon: "warning",
      });
      return;
    }

    // 정말 저장하시겠습니까?
    Swal.fire({
      title: "저장하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          name,
          phone,
          notes,
        };

        try {
          const res = await $post("/api/inquiry", data);
          if (res.status === 200) {
            // 저장되었습니다.
            Swal.fire({
              title: "저장되었습니다.",
              icon: "success",
            }).then(() => {
              btnCloseRef.current.click();
            });
          }
        } catch (error) {
          // 저장에 실패했습니다.
          Swal.fire({
            title: "저장에 실패했습니다.",
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setPhone(userData.phone);
    }
  }, [userData]);

  useEffect(() => {
    btnInquiryRef.current.click();
  }, []);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#inquiryModal"
        ref={btnInquiryRef}
      ></button>
      <div
        className="modal fade"
        id="inquiryModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="inquiryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal: Header */}
            <div className="modal-header">
              <h5 className="modal-title">별도 문의</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              />
            </div>
            {/* Modal: Header */}
            {/* Modal: Body */}
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="inquiry-name" className="form-label">
                    문의자명
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inquiry-name"
                    placeholder="문의자명을 입력하세요."
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inquiry-phone" className="form-label">
                    연락처
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inquiry-phone"
                    placeholder="연락처를 입력하세요. (ex. 010-0000-0000)"
                    value={phone || ""}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="inquiry-notes" className="form-label">
                    문의사항
                  </label>
                  <textarea
                    className="
                      form-control
                      form-control-solid
                      h-auto
                      min-h-100px
                    "
                    id="inquiry-notes"
                    rows={6}
                    placeholder="문의사항을 입력하세요."
                    value={notes || ""}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Modal: Body */}
            {/* Modal: Footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => saveInquiry()}
              >
                문의완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InquiryModal;
